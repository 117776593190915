.privacyContainer {
    padding: 30px;
    max-width: 800px;
    margin: auto;
    line-height: 1.6;
    font-size: 14px;
    padding-bottom: 100px;
    padding-top: 90px;

    /* Style the list */
    ul.breadcrumb {
        list-style: none;
    }

    /* Display list items side by side */
    ul.breadcrumb li {
        display: inline;
        font-size: 18px;
        color: #aaa;
    }

    /* Add a slash symbol (/) before/behind each list item */
    ul.breadcrumb li + li:before {
        padding: 8px;
        color: white;
        content: ">\00a0";
    }

    /* Add a color to all links inside the list */
    ul.breadcrumb li a {
        color: white;
        text-decoration: none;
    }

    /* Add a color on mouse-over */
    ul.breadcrumb li a:hover {
        color: #aaa;
        text-decoration: underline;
    }

    h1,
    h2,
    h3 {
        color: #aaa;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    li {
        color: white;
    }

    p {
        margin-bottom: 15px;
        text-align: justify;
    }

    a {
        color: #0066cc;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }

    .disclaimer {
        font-style: italic;
    }
}
