.navbar {
    display: flex;
    white-space: nowrap;

    .navItem {
        margin-left: 5rem;
        cursor: pointer;

        button {
            background: none;
            border: none;
            color: white;
            font-size: 1.6rem;
            font-weight: 400;
            cursor: pointer;

            &:hover {
                color: #482ff7;
                transform: scale(1.1);
            }

            &:focus {
                outline: none;
            }
        }

        .activeLink {
            color: #482ff7;
            font-weight: bold;
        }
    }
}
