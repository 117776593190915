.btn {
    display: inline-block;
    padding: 16px 27px 16px 27px;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    background-color: #225;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.12);
    cursor: pointer;
    outline: none;
    color: rgba(238, 238, 238, 0.877);
    letter-spacing: 0.12em;
    border-style: solid;
    border-width: 2px 2px 2px 2px;
    transition: all 0.3s ease;
}

.languageBtn {
    padding: 9px;
    margin-top: 6px;
    border-radius: 15px;
    background-color: transparent;
    color: #fff;
    border: 1px #fff solid;
}
