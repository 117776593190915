.heading {
    display: grid;
    padding-bottom: 0.5rem;
    color: white;
    text-transform: uppercase;
    font-family: "Eczar", serif;
    font-size: 2rem;
    border-bottom: 0.1rem solid #a2a2a2;
    text-align: center;
    width: fit-content;
    margin: 50px auto;
}
