.item {
    width: 500px;
    height: 300px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border: 1px solid #aaa;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(10px);
    transition:
        transform 0.5s,
        background-color 0.5s;

    &:hover {
        transform: scale(1.05);
        background-color: lighten(hsl(256, 25%, 29%), 20%);
    }

    .card {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        cursor: pointer;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        text-align: left;
        padding: 20px;
    }

    .cardFront {
        justify-content: flex-start;
    }

    .cardBack {
        color: white;
        font-size: 17px;
        margin-top: 2rem;
    }
}

@media only screen and (max-width: 1110px) {
    .item {
        width: 489px;
        height: 330px;
    }
}

@media only screen and (max-width: 1030px) {
    .item {
        max-width: 440px;
        height: 330px;
    }
}

@media only screen and (max-width: 950px) {
    .item {
        max-width: 360px;
        height: 330px;
    }
}

@media only screen and (max-width: 825px) {
    .item {
        max-width: 346px;
        height: 350px;
    }
}
@media only screen and (max-width: 480px) {
    .item {
        height: 380px;
    }
}
