.about {
    .heading {
        display: grid;
        padding-bottom: 0.5rem;
        text-shadow: 0 3px 0 rgb(238, 238, 238);
        text-transform: uppercase;
        font-family: "Eczar", serif;
        font-size: 2rem;
        border-bottom: 0.1rem solid #a2a2a2;
        text-align: center;
        width: fit-content;
        margin: 50px auto;
    }

    .container {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
        gap: 2em;
        padding: 2rem;
        border-radius: 8px;
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
        margin: 20px auto;
        border: 1px solid #aaa;
        background: rgba(0, 0, 0, 0.6);
        backdrop-filter: blur(10px);
        transition:
            transform 0.5s,
            background-color 0.5s;

        .description,
        .context {
            display: grid;
        }

        .title,
        .txtContainer {
            text-align: start;
            margin: auto;
        }

        .txtContainer {
            display: flex;
            flex-direction: column;
            gap: 1.5rem;
            font-size: 17px;
            margin-top: 2rem;
        }

        .context {
            grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
            gap: 2em;
            .box {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                font-size: 20px;
                padding: 1.2rem;
                border-radius: 15px;
                box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
                background-color: #225;
                color: aliceblue;
                transition: transform 0.3s ease-in-out;

                &:hover {
                    transform: translateY(-5px);
                    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
                }
            }
        }
    }

    @media only screen and (max-width: 768px) {
        .context {
            grid-template-columns: 1fr;
        }

        .box {
            font-size: 1rem;
        }
    }

    @media only screen and (max-width: 480px) {
        .container {
            width: 100%;
        }
    }
}
