.services {
    background-color: transparent;
    margin-top: 120px;
    .heading {
        display: grid;
        padding-bottom: 0.5rem;
        text-shadow: 0 3px 0 rgb(238, 238, 238);
        text-transform: uppercase;
        font-family: "Eczar", serif;
        font-size: 2rem;
        border-bottom: 0.1rem solid #a2a2a2;
        text-align: center;
        width: fit-content;
        margin: 50px auto;
    }
    .container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 5rem;
        margin: 0 auto;

        @media (max-width: 830px) {
            justify-content: center;
        }
    }
}
