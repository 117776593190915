.contact {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 100px;

    .container {
        background-color: #1a1919;
        padding: 3rem;
        border-radius: 8px;
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
        margin: 20px auto;
        border: 1px solid #aaa;
        background: rgba(0, 0, 0, 0.6);
        backdrop-filter: blur(10px);
        transition:
            transform 0.5s,
            background-color 0.5s;
        display: flex;
        flex-direction: column;
        gap: 2rem;
        align-items: center;
        margin-bottom: 1rem;
        margin: 1rem;
        width: 100%;

        .title {
            font-size: 1.9rem;
        }

        .responseTime {
            margin-top: 1rem;
            color: #a2a2a2;
            font-size: 1.3rem;
        }

        .socialMedia {
            display: flex;
            justify-content: center;
            gap: 10rem;
            margin-top: 1rem;
        }

        @media only screen and (max-width: 768px) {
            .socialMedia {
                gap: 5rem;
            }
        }
    }

    @media only screen and (max-width: 500px) {
        .container {
            width: 350px;
            .socialMedia {
                gap: 0.5rem;
            }
        }
    }

    .contactOptions {
        list-style-type: none;
        padding: 0;
        text-align: center;

        li {
            margin: 10px 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}
