.skills {
    .dropdowns {
        display: flex;
        flex-direction: column;
        gap: 5rem;
        cursor: pointer;
    }

    .slider {
        margin-top: 3rem;

        img {
            border-radius: 8px;
            border-bottom: 0.1rem solid #a2a2a2;
        }
    }
}
