.socialBtnLink {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border: 2px solid #aaa;
    border-radius: 9999px;
    text-decoration: none;
    color: #eee;
    transition: all 0.5s ease-in-out;

    &:hover {
        background-color: #007bff;
        color: #000;
        .socialBtnTitle {
            width: 70px;
        }
    }
}

.socialBtnTitle {
    display: inline-block;
    font-size: 1.25rem;
    font-weight: 600;
    letter-spacing: widest;
    width: 0;
    overflow: hidden;
    transition: width 0.5s ease-in-out;
    text-align: center;
    margin-left: 0.5rem;
}
