.home {
    display: grid;
    .homeContainer {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));

        @media only screen and (max-width: 800px) {
            flex-direction: column-reverse;
        }

        @media only screen and (max-width: 720px) {
            .photo {
                display: flex;
                justify-content: flex-start;
                order: -1;
            }
        }

        .description {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .buttons {
                display: flex;
                gap: 1.5rem;
                font-size: 16px;
            }
        }

        .photo {
            display: flex;
            justify-content: center;
            margin-top: 7rem;
            height: 500px;
            max-width: 100%;
            overflow-x: hidden;
            border-radius: 5px;

            img {
                height: auto;
                border-radius: 5px;
            }
        }

        .name {
            text-transform: uppercase;
            font-family: "Eczar", serif;
            font-size: 4rem;
        }

        .job {
            padding-bottom: 2rem;
            font-family: "Spectral", serif;
            font-size: 2.7rem;
        }

        p {
            color: aliceblue;
        }
    }
}
