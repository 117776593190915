.header {
  position: fixed;
  z-index: 1000;
  width: 100%;

  .navbar {
    background: #1a1919;
    box-sizing: border-box;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 1rem 1.5rem;

    .containerNav {
      display: flex;
      flex-basis: 50%;

      .navLogo {
        display: flex;
        justify-content: flex-start;
      }

      .navButton {
        position: absolute;
        left: 20%;
      }

      @media only screen and (max-width: 480px) {
        .navButton {
          left: 30%;
        }
      }
    }

    .navMenu {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 19px;

      .navItem {
        margin-left: 5rem;

        .navLink {
          font-size: 1.6rem;
          font-weight: 400;
          color: white;
        }
        .navLink:hover {
          color: #482ff7;
        }
      }
    }

    .hamburger {
      display: none;
      cursor: pointer;
      font-size: 2rem;
      color: white;

      .bar {
        display: block;
        width: 25px;
        height: 3px;
        margin: 5px auto;
        -webkit-transition: all 0.3s ease-in-out;
        transition: all 0.3s ease-in-out;
        background-color: white;
      }
    }

    ul {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding: 0;
      width: 100%;
      list-style-type: none;

      li {
        margin: 0 10px;
      }
    }

    a {
      font-size: 1rem;
      text-decoration: none;
      color: white;
      transition: 0.3s;
    }

    a:hover {
      color: #a2a2a2;
    }

    @media only screen and (max-width: 900px) {
      .navMenu {
        position: fixed;
        right: -100%;
        top: 5rem;
        flex-direction: column;
        width: 50%;
        height: 100%;
        background-color: #1a1919;
        text-align: center;
        justify-content: space-evenly;
        transition: 0.3s;
        box-shadow: 0 10px 27px rgba(0, 0, 0, 0.05);
      }

      .navMenu.active {
        right: 0;
      }

      .navItem {
        margin: 2.5rem 0;
      }

      .hamburger {
        display: block;
        cursor: pointer;
      }
    }
  }
}
