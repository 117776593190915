.experience {
    .heading {
        display: grid;
        padding-bottom: 0.5rem;
        text-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
        text-transform: uppercase;
        font-family: "Eczar", serif;
        font-size: 2rem;
        border-bottom: 0.1rem solid #a2a2a2;
        text-align: center;
        margin: 50px auto;
    }

    .vertical-timeline-element-title,
    .vertical-timeline-element-subtitle {
        color: #e8eaf6;
        font-size: 2rem;
    }

    h3 {
        font-size: 2rem;
    }

    h5 {
        font-size: 1.3rem;
    }

    ul {
        padding-left: 20px;
        list-style-type: circle;
        color: #c5cae9;

        li {
            font-size: 1.5rem;
        }
    }

    p {
        font-size: 1.5rem;
        color: white;
    }

    .vertical-timeline-element--work {
        contentstyle: {
            background: "hsl(233, 30%, 21%)";
            color: "#fff";
        }
        contentarrowstyle: {
            borderright: "7px solid  rgb(33, 150, 243)";
        }
        iconstyle: {
            background: "rgb(33, 33, 77)";
            color: "#fff";
        }
    }
}
