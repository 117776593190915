.footer {
    background: #1a1919;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 1rem 1.6rem;
    font-size: 14px;
    clear: both;
    position: fixed;
    overflow: hidden;
    bottom: 0;
    transition: transform 0.3s ease-in-out;
}

.hidden {
    transform: translateY(100%);
}

.visible {
    transform: translateY(0);
}

.footerLinks {
    display: flex;
    gap: 1rem;

    a {
        color: #fff;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
        }
    }
}
