.contentContainer {
  margin-left: 3rem;
  margin-right: 3rem;
}

@media (max-width: 768px) {
  .contentContainer {
    margin-left: 3rem;
    margin-right: 3rem;
  }
}
#tsparticles {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}
