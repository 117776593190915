.dropdownContainer {
    font-family: "Spectral", serif;
    font-size: 2.7rem;
    color: aliceblue;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .dropdownTitle {
        display: flex;
        flex-direction: column;
        .experience {
            font-size: 1.6rem;
            color: #f5f5f5;
            margin-bottom: 0.5rem;
        }
    }
}
